import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

import { Layout, Map } from '../components'
import { GOOGLE_MAPS_KEY } from '../constants'
import { mapTheme } from '../data/map'

import { container, padding, content, pageTitle, bgIcon, menuPadding } from '../styles/global';
import { media } from '../styles/utils';
import Post from '../components/Post/Post'

const VenuePosts = ({ pageContext, ...props }) => {
	const data = pageContext.acf_json

	// category 10 is Press category on wordpress
	const media = data?.filter(b => b?.categories?.includes(10))
	const articles = data?.filter(b => !b?.categories?.includes(10))

	return (
		<Layout
			meta={data && data.seo}
			menu={pageContext.menu}
		>
			<Container>
				<Heading>{pageContext.sectionTitle}</Heading>

				<Subheading>Articles</Subheading>

				<Content>
					{articles
						.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
						.map(post => {
							return (
								<Post
									title={post.title}
									date={post.date}
									link={post.link}
								>
									{post.excerpt}
								</Post>
							)
						})}
				</Content>

				<Subheading>Media</Subheading>

				<Content>
					{media
						.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
						.map(post => {
							return (
								<Post
									title={post.title}
									date={post.date}
									link={post.link}
								>
									{post.excerpt}
								</Post>
							)
						})}
				</Content>
			</Container>
		</Layout>
	)
}


// Shared

const Heading = styled.div``

const Subheading = styled.div`
	font-size: 48px;
	padding-bottom: 24px;

  font-weight: bold;
	font-family: 'learning-curve';
`

// Layout

const Container = styled.div`
	${container}
	${padding}
	${menuPadding}
	
	flex-direction: column;
	align-items: center;
	flex: 1;

	> ${Heading} {
		${pageTitle}
	}
	background-color: #F1BFB2;
`

const Content = styled.div`
	${content}
	justify-content: flex-start;
	padding-bottom: 32px;
`

export default VenuePosts

